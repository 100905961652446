import { useEffect, useState, useRef } from 'react';
import Dots from '../../Atoms/Loaders/Dots';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import KexLink from '../../Kex/KexLink';
import QuickSearchProductCard from '../../Molecules/QuickSearchProductCard/QuickSearchProductCard';
import ProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import ContentModel from '../../Search/Models/ContentModel.interface';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';
import SearchLinkResult from '../../Search/Models/SearchLinkResult.interface';
import {
  EventDispatcher,
  ON_SEARCH,
  ON_MODAL_SEARCH,
} from '../../Shared/Common/EventDispatcher';
import useMedia from '../../Shared/Hooks/useMedia';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';

type PropTypes = {
  searchResult?: FullSearchResult;
  isOpen: boolean;
  currentSearchPhrase: string;
  setInputActive?: any;
  onSearchResultChange?: any;
  closeSearchModal: () => void;
};

function QuickSearchModal({
  searchResult,
  isOpen,
  currentSearchPhrase,
  setInputActive,
  onSearchResultChange,
  closeSearchModal,
}: PropTypes) {
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const {
    'search/showAllSearchResults': showAllSearchResultsLabel,
    'search/pages': pagesLabel,
    'searchPage/products': productsLabel,
    'search/category': categoryLabel,
    'search/noResults': noResultsLabel,
  } = useTranslationData();

  const onSearch = (value: boolean) => {
    setIsSearching(value);
  };

  const searchModalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(searchModalRef, () => {
    if (isDesktop && isOpen) {
      closeSearchModal();
      setInputActive(false);
    }
  });

  const doSearch = () => {
    if (currentSearchPhrase.length > 0) {
      EventDispatcher.dispatch(ON_MODAL_SEARCH, true);
    }
  };

  const noSearchResult =
    !searchResult?.contentSearchResult &&
    !searchResult?.productSearchResult &&
    ((searchResult?.categorySearchResult &&
      !!!searchResult?.categorySearchResult.length) ||
      !searchResult?.categorySearchResult) &&
    !searchResult?.planogramSearchResult;

  const hasContentResults =
    searchResult?.contentSearchResult || searchResult?.categorySearchResult;

  useEffect(() => {
    EventDispatcher.subscribe(ON_SEARCH, onSearch);
    return () => {
      EventDispatcher.unsubscribe(ON_SEARCH, onSearch);
    };
  });

  const onResultItemClick = () => {
    setInputActive(false);
    onSearchResultChange(undefined, '');
  };

  return isOpen ? (
    <>
      <SearchResultModalContainer ref={searchModalRef}>
        {isSearching ? (
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        ) : !isSearching && searchResult && noSearchResult ? (
          <NoResultsWrapper>{noResultsLabel}</NoResultsWrapper>
        ) : (
          <ModalTabsWrapper>
            {searchResult && searchResult.productSearchResult?.availableItems && (
              <ProductsResultWrapper>
                <ResultCategory noMargin>{productsLabel}</ResultCategory>
                {searchResult?.productSearchResult?.availableItems && (
                  <ResultList>
                    {searchResult?.productSearchResult?.items.map(
                      (product: ProductCardModel) =>
                        product.url && (
                          <ProductListItem key={product.url + product.name}>
                            <QuickSearchProductCard
                              product={product}
                              productCardClick={onResultItemClick}
                            />
                          </ProductListItem>
                        )
                    )}
                  </ResultList>
                )}
                <VerticalDivider />
              </ProductsResultWrapper>
            )}
            {searchResult && hasContentResults && (
              <>
                <ContentResultWrapper>
                  {!!searchResult?.categorySearchResult?.length && (
                    <div>
                      <ResultCategory noMargin>{categoryLabel}</ResultCategory>
                      <ResultList>
                        {searchResult?.categorySearchResult?.map(
                          (item: SearchLinkResult) => (
                            <ListItem
                              key={item.url + item.name}
                              onClick={onResultItemClick}
                            >
                              <StyledLink href={item.url}>
                                {item.name}
                              </StyledLink>
                            </ListItem>
                          )
                        )}
                      </ResultList>
                    </div>
                  )}
                  {searchResult?.contentSearchResult?.availableItems && (
                    <div>
                      <ResultCategory
                        noMargin
                        margin={!!searchResult?.categorySearchResult?.length}
                      >
                        {pagesLabel}
                      </ResultCategory>
                      <ResultList>
                        {searchResult?.contentSearchResult?.items.map(
                          (item: ContentModel) =>
                            item.src && (
                              <ListItem
                                key={item.src + item.name}
                                onClick={onResultItemClick}
                              >
                                <StyledLink href={item.src}>
                                  {item.name}
                                </StyledLink>
                              </ListItem>
                            )
                        )}
                      </ResultList>
                    </div>
                  )}
                </ContentResultWrapper>
              </>
            )}
          </ModalTabsWrapper>
        )}
        {!noSearchResult && !isSearching && (
          <ShowAllButton onClick={() => doSearch()}>
            {showAllSearchResultsLabel}
          </ShowAllButton>
        )}
      </SearchResultModalContainer>
    </>
  ) : (
    <></>
  );
}

const VerticalDivider = styled('div', {
  backgroundColor: '$grey100',
  h: 0.25,
  w: '100%',
  my: 10,
});

const SearchResultModalContainer = styled('div', {
  position: 'fixed',
  t: 47,
  l: 4,
  r: 4,
  b: 4,
  px: 6,
  pt: 8,
  pb: 16,
  zIndex: 20,
  fs: 6,
  backgroundColor: '$white',
  h: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',

  '@mediaMinLarge': {
    maxH: 'calc(100vh - 200px)',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.19717)',
    position: 'absolute',
    t: '54px',
    bottom: 'unset',
    w: '100%',
    l: 0,
    r: 0,
    mx: 'auto',
    h: 'auto',
  },
});

const ModalTabsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const ShowAllButton = styled('button', {
  position: 'absolute',
  t: 8,
  r: 6,
  fontWeight: '$fw700',
  fs: 5,
  textTransform: 'uppercase',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ContentResultWrapper = styled('div', {
  w: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinLarge': {
    gridColumnEnd: 'span 1',
  },
});

const ProductsResultWrapper = styled('div', {
  w: '100%',
  '@mediaMinLarge': {
    gridColumnEnd: 'span 1',
  },
});

const StyledLink = styled(KexLink, {
  fs: 6,
  fontWeight: '$fw400',
  lineHeight: '20px',
});

const ListItem = styled('li', {
  display: 'flex',
  '&:not(:last-child)': {
    mb: 2,
  },
});

const ProductListItem = styled('li', {
  '&:not(:last-of-type)': {
    mb: 4,
  },
});

const ResultCategory = styled(H2, {
  fontWeight: '$fw300',
  fs: 7,
  lineHeight: '23px',
  variants: {
    margin: {
      true: {
        mt: 8,
      },
    },
  },
});

const ResultList = styled('ul', {
  listStyle: 'none',
  mt: 4,
});

const DotsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  pb: 9,
});

const NoResultsWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  py: 9,
});

export default QuickSearchModal;

import KexLink from '../../Kex/KexLink';
import ProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import { styled } from '../../stitches.config';
import { useState } from 'react';
import Image from '../../Atoms/Image/Image';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import QuantitySelectorSearch from '../../Atoms/Quantity/QuantitySelectorSearch';
import { UpdateCart } from '../../Pages/CartPage/Cart';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import Button from '../../Atoms/Buttons/Button';

type PropType = {
  product: ProductCardModel;
  productCardClick: () => void;
};

function QuickSearchProductCard({ product, productCardClick }: PropType) {
  const [quantity, setQuantity] = useState<number>(
    product.minimumSalesQuantity
  );
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const { 'product/artno': artnoLabel } = useTranslationData();
  const { languageRoute } = useAppSettingsData();
  const { channelId, pageId } = useCurrentPage<PageModelBase>();
  const { 'common/buyNow': buyNowLabel } = useTranslationData();

  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    channelSystemId: channelId,
  });
  const onQuantityChange = (value: number) => {
    setQuantity(value);
  };
  function addToCart() {
    setIsAdding(true);
    UpdateCart(product.code, quantity, languageRoute, litiumContext)
      .then(() => setIsAdding(false))
      .catch(() => setIsAdding(false));
  }

  return (
    <Root href={product.url}>
      <LeftSection>
        <ImageWrapper>
          <StyledImage src={product.image?.src} alt={product.image?.alt} />
        </ImageWrapper>
        <Attributes>
          {product.brand && (
            <Brand>
              <span>{product.brand}</span>
            </Brand>
          )}
          <ProductName>{product.name}</ProductName>
          <ProductPrice>{product.price}</ProductPrice>
          {product.code && (
            <Code>
              <span>
                {artnoLabel} {product.code}
              </span>
            </Code>
          )}
        </Attributes>
      </LeftSection>
    </Root>
  );
}

export default QuickSearchProductCard;

const Root = styled(KexLink, {
  w: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fs: 6,
  pb: 4,
  borderBottom: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: 'transparent',
});

const RightSection = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
});

const ProductPrice = styled('div', {
  pb: 0,
});

const AddToCart = styled('div', {
  justifyContent: 'flex-end',
  flexDirection: 'column',
  gap: '10px',
  mt: 4,
  '@mediaMinSmall': {
    mt: 'auto',
  },
  h: '100%',
  maxW: '78px',
  display: 'flex',
  pt: 0,
  w: '100%',
});

const ProductBuyButton = styled('button', {
  py: 1,
  px: 3,
  borderRadius: '40px',
  fs: 4,
  fontWeight: '$fw700',
  backgroundColor: '$buttonSecondary',
  color: '$buttonHoverBackgroundPrimary',
});

const LeftSection = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  fs: 6,
  fontWeight: '$fw300',
});

const ImageWrapper = styled('div', {
  wh: 10,
  minW: 10,
});

const StyledImage = styled(Image, {
  objectFit: 'contain',
  h: '100%',
});

const Attributes = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  ml: 2,
  color: '$textPrimary',
});

const Brand = styled('div', {
  textDecoration: 'none',
  lineHeight: '16px',
  color: '$textPrimary',
});

const Code = styled('div', {
  fontSize: '9px',
  lineHeight: '16px',
  letterSpacing: '1px',
  color: '$searchInputTextPrimary',
});

const ProductName = styled('span', {
  maxW: '332px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  lineHeight: '20px',
});

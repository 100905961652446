import { useLayoutEffect } from 'react';
import useMedia from './useMedia';

const activeUseLockBodyScrolls = {};
let scrollPos = null;

function useLockBodyScroll({ mediaQuery = false, lock = true } = {}) {
  const mediaQueryIsTrue = useMedia(mediaQuery);

  /**
   *  for some reason we can't use useEffect because of infinite rerenders in ios
   * */
  useLayoutEffect(() => {
    if (mediaQuery && !mediaQueryIsTrue) return;

    let scrollPosition,
      rootEle = document.getElementById('root'),
      header = document.getElementById('fixed-site-header');
    const id = new Date().valueOf();

    if ((!mediaQuery || (mediaQueryIsTrue && mediaQuery)) && lock) {
      scrollPosition = window.pageYOffset;

      if (scrollPos === null) {
        rootEle.style.top = `${-scrollPosition}px`;
        /*
         * global class declared in index.scss
         */
        rootEle.classList.add('rootlocked');
      }

      activeUseLockBodyScrolls[id] =
        scrollPos === null ? scrollPosition : scrollPos;
    }

    return () => {
      const idScrollPos = activeUseLockBodyScrolls[id];
      delete activeUseLockBodyScrolls[id];
      if (Object.isEmpty(activeUseLockBodyScrolls)) {
        header && (header.style.top = `0px`);
        /*
         * global class declared in index.scss
         */
        rootEle.classList.remove('rootlocked');
        window.scrollTo(0, idScrollPos);
      }
    };
  }, [lock, mediaQuery, mediaQueryIsTrue]);

  return mediaQueryIsTrue;
}

export default useLockBodyScroll;

import React, { forwardRef, useRef } from 'react';
import ContentContainer from '../../Molecules/ContentContainer/ContentContainer';
import { MenuIcon, CartIcon, ProfileIcon } from '../../Atoms/Icons';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';
import DesktopQuickSearch from '../../Organisms/DesktopQuickSearch/DesktopQuickSearch';
import KexLink from '../../Kex/KexLink';
import { GetCart } from '../../Pages/CartPage/Cart';
import Divider from '../../Atoms/Divider/Divider';
import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import Link from '../../Shared/Models/Link.interface';
import ImageModel from '../../Assets/Models/ImageModel.interface';
import Image from '../../Atoms/Image/Image';
import LanguagePickerModel from '../../Shared/Models/LanguagePickerModel.interface';
import LanguagePicker from '../../Molecules/LanguagePicker/LanguagePicker';
import XhtmlArea from '../../Molecules/XHtmlArea/XHtmlArea';
import { useHeaderData } from './Header';

type PropTypes = {
  toggleMenu: () => void;
  openMiniCart: () => void;
  openUserMenu: () => void;
  openQuickSearch: () => void;
  closeSearchModal: () => void;
  quickSearchIsOpen: boolean;
  menuIsOpen: boolean;
  miniCartBtnRef: React.RefObject<HTMLButtonElement>;
  userMenuBtnRef: React.RefObject<HTMLButtonElement>;
  topLinks?: Link[];
  logo?: ImageModel;
  languagePicker?: LanguagePickerModel;
  topLinkPrivate?: Link;
  topLinkCompany?: Link;
  topText: string;
  topResellerLink: Link;
};

const HeaderMain = forwardRef(
  (
    {
      toggleMenu,
      menuIsOpen,
      openMiniCart,
      miniCartBtnRef,
      openUserMenu,
      openQuickSearch,
      userMenuBtnRef,
      quickSearchIsOpen,
      closeSearchModal,
      topLinks,
      logo,
      languagePicker,
      topLinkPrivate,
      topLinkCompany,
      topText,
      topResellerLink,
    }: PropTypes,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const {
      languageRoute,
      staticPages: { loginPage },
    } = useAppSettingsData();
    const { dontAllowLogin } = useHeaderData();
    // const [searchModalOpen, setSearchModalOpen] = useState(false);
    const {
      'common/login': loginText,
      'common/profile': profileText,
      'common/cart': cartText,
      'header/private': privateText,
      'header/company': companyText,
      'header/reseller': resellerText,
    } = useTranslationData();
    const { authenticated } = useUserStateData();
    const { cart, isLoading } = GetCart();
    const nbrRef = useRef<HTMLSpanElement>(null);
    const isEmpty = cart.numberOfItems === 0;
    const hasTopLinks = topLinks && !!topLinks.length;

    return (
      <>
        <Wrapper hasTopSection={true} hasTopLinks={hasTopLinks}>
          <TopSection>
            <TopContentContainer>
              {topLinkPrivate?.href && topLinkCompany?.href && (
                <TopColumn>
                  <div>
                    <StyledKexLink href={topLinkPrivate.href}>
                      {privateText}
                    </StyledKexLink>{' '}
                    /{' '}
                    <StyledKexLink href={topLinkCompany.href}>
                      {companyText}
                    </StyledKexLink>
                  </div>
                </TopColumn>
              )}
              <TopColumn>
                {topText && <XhtmlArea content={topText} />}
              </TopColumn>
              <TopColumn>
                {languagePicker && (
                  <div>
                    <LanguagePicker languagePicker={languagePicker} />
                  </div>
                )}
                {topResellerLink?.href && (
                  <div>
                    <StyledKexLink href={topResellerLink.href}>
                      {resellerText}
                    </StyledKexLink>
                  </div>
                )}
              </TopColumn>
            </TopContentContainer>
          </TopSection>
          <StyledContainer>
            <Container>
              <Flex>
                <TextButton onClick={toggleMenu} ref={ref}>
                  <MenuIcon isOpen={menuIsOpen} />
                </TextButton>
                <Divider type="headerLogo" />
                <KexLink href={languageRoute ? `/${languageRoute}/` : '/'}>
                  <Image src={logo?.src} alt={logo?.alt} />
                </KexLink>
              </Flex>
              <DesktopQuickSearch
                isOpen={quickSearchIsOpen}
                openQuickSearch={openQuickSearch}
                closeSearchModal={closeSearchModal}
              />
              <Right>
                {!dontAllowLogin && (
                  <>
                    <IconButton>
                      {!authenticated ? (
                        <IconLink href={loginPage} noUnderline>
                          <IconArea>
                            <ProfileIcon size="m" />
                          </IconArea>
                          {loginText}
                        </IconLink>
                      ) : (
                        <UserMenuButton
                          onClick={openUserMenu}
                          ref={userMenuBtnRef}
                        >
                          <IconArea>
                            <ProfileIcon size="m" />
                          </IconArea>
                          {profileText}
                        </UserMenuButton>
                      )}
                    </IconButton>
                    <Divider type="header" />
                    <IconButton
                      onClick={() => openMiniCart()}
                      ref={miniCartBtnRef}
                    >
                      <IconArea>
                        <CartIcon size="m" />
                      </IconArea>
                      {cartText}
                      {!isLoading && !isEmpty && (
                        <CartItemsNotifier>
                          <span ref={nbrRef}>
                            {cart.numberOfItems > 99
                              ? '99+'
                              : cart.numberOfItems}
                          </span>
                        </CartItemsNotifier>
                      )}
                    </IconButton>
                  </>
                )}
              </Right>
            </Container>
          </StyledContainer>
          {topLinks && !!topLinks.length && (
            <BottomSection>
              {topLinks.map((topLink) => (
                <StyledLink key={topLink.href} href={topLink.href}>
                  {topLink.text}
                </StyledLink>
              ))}
            </BottomSection>
          )}
        </Wrapper>
      </>
    );
  }
);

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  '& img': {
    maxW: '70%',
  },
});

const TopColumn = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  fw: '$fw400',
  lineHeight: '24px',
  letterSpacing: '1px',
  gap: '10px',
  '& a': {
    fontSize: '12px',
    fw: '$fw400',
    lineHeight: '24px',
    letterSpacing: '1px',
  },
  '& div': {
    alignSelf: 'center',
  },
});

const StyledKexLink = styled(KexLink, {
  fontWeight: '$fw400',
  textDecoration: 'underline',
});

const Wrapper = styled('div', {
  position: 'relative',
  zIndex: '$Header',
  backgroundColor: '$backgroundPrimary',
  display: 'flex',
  flexWrap: 'wrap',
  pt: '20px',
  pb: 0,
  variants: {
    hasTopSection: {
      true: {
        pt: '0',
      },
    },
    hasTopLinks: {
      true: {
        pb: '20px',
      },
    },
  },
});

const TopSection = styled('div', {
  background: '#DDDAD8',
  width: '100%',
  p: '20px 20px 0 20px',
});

const TopContentContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  maxW: '$contentMaxWidth',
  mb: 3,
  px: '20px',
  mx: 'auto',
});

const UserMenuButton = styled('button', {
  color: '$backgroundSecondary',
  ml: 4,
  mr: 8,
  pxy: 2,
});

const StyledContainer = styled(ContentContainer, {
  backgroundColor: '#F7F6F5',
  py: '20px',
  mx: 0,
  w: '100%',
  maxWidth: '100%',
});

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  px: '20px',
  maxWidth: '$contentMaxWidth',
  mx: 'auto',
});

const BottomSection = styled('div', {
  display: 'flex',
  maxWidth: '$contentMaxWidth',
  mx: 'auto',
  mt: '20px',
  mb: 0,
  width: '100%',
  px: '20px',
  py: 0,
  g: 10,
});

const StyledLink = styled(KexLink, {
  fs: 7,
});

const IconLink = styled(KexLink, {
  fontWeight: '$fw400',
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
});

const TextButton = styled('button', {
  backgroundColor: 'transparent',
  pxy: 0,
  fontWeight: '$300',
  color: '$textPrimary',
  display: 'flex',
  alignItems: 'center',
  '&:focus': {
    outlineStyle: 'none',
  },
});

const Right = styled('div', {
  color: '$textPrimary',
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'stretch',
});

const IconButton = styled('button', {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  position: 'relative',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  '&:focus': {
    outlineStyle: 'none',
  },
  '& svg': {
    m: '0 auto',
  },
});

const IconArea = styled('div', {
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #494948',
  br: '100%',
  wh: 10,
  mb: 1,
});

const CartItemsNotifier = styled('span', {
  position: 'absolute',
  t: -1.25,
  r: -2,
  backgroundColor: '$black',
  color: '$white',
  br: 50,
  fontSize: '10px',
  fontWeight: '$fw600',
  lineHeight: '14px',
  letterSpacing: 0,
  minW: '24px',
  minH: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'hidden',
  zIndex: '$Cart',
});

export default HeaderMain;

type MenuStateType = {
  menuIsOpen: boolean;
  mountMenu: boolean;
};
type MenuActionType = 'mountAndOpen' | 'closeMenu' | 'unMountMenu';

const menuReducer = (state: MenuStateType, action: MenuActionType) => {
  switch (action) {
    case 'mountAndOpen': {
      return {
        ...state,
        menuIsOpen: true,
        mountMenu: true,
      };
    }
    case 'closeMenu': {
      return {
        ...state,
        menuIsOpen: false,
      };
    }
    case 'unMountMenu': {
      return {
        ...state,
        menuIsOpen: false,
        mountMenu: false,
      };
    }
  }
};

export default menuReducer;

import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { CloseIcon, SearchIcon } from '../../Atoms/Icons';
import { useKexNavigate } from '../../Kex/KexRouter/KexRouter';
import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import {
  EventDispatcher,
  ON_MODAL_SEARCH,
  ON_SEARCH_MODAL_OPEN_CHANGE,
} from '../../Shared/Common/EventDispatcher';
import QuickSearchModal from '../QuickSearchModal/QuickSearchModal';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';
import { QuickSearch } from '../../Pages/SearchPage/Search';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';

type PropTypes = {
  openQuickSearch: any;
  isOpen: boolean;
  closeSearchModal: () => void;
};

let typeTimer: any;

function DesktopQuickSearch({
  isOpen,
  openQuickSearch,
  closeSearchModal,
}: PropTypes) {
  const {
    staticPages: { searchPage },
    languageRoute,
    startPageId,
  } = useAppSettingsData();
  const { channelId, pageId } = useCurrentPage<PageModelBase>();
  const { 'search/placeholder': placeholderText } = useTranslationData();

  const kexNavigate = useKexNavigate();
  const [query, setQuery] = useState<string>('');

  const [searchResult, setSearchResult] = useState<FullSearchResult>();
  const [isSearchInputActive, setIsSearchInputActive] = useState(false);
  const [currentSearchPhrase, setCurrentSearchPhrase] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const baseUrl = `/api/${languageRoute || 'en'}/search/QuickSearch?query=`;

  const onSearchResultChange = useCallback((res, searchPhrase) => {
    setCurrentSearchPhrase(searchPhrase);
    setSearchResult(res);
  }, []);

  const requestContext = JSON.stringify({
    currentPageSystemId: startPageId,
    categorySystemId: pageId,
    channelSystemId: channelId,
  });

  const doSearch = () => {
    if (query.length > 0 && searchResult) {
      // EventDispatcher.dispatch(ON_MOBILE_SEARCH_OPEN_CHANGE, false);
      // EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, false);
      closeSearchModal();
      // setQuery('');
      setIsSearchInputActive(false);
      inputRef.current?.blur();
      // onSearchResultChange(undefined, '');
      kexNavigate(`${searchPage}?searchQuery=${query}`);
    }
  };

  useEffect(() => {
    EventDispatcher.subscribe(ON_MODAL_SEARCH, doSearch);
    return () => {
      EventDispatcher.unsubscribe(ON_MODAL_SEARCH, doSearch);
    };
  });

  const onCrossClick = () => {
    setCurrentSearchPhrase('');
    inputRef.current?.focus();
  };

  const onInputChange = useCallback(
    (value: string) => {
      openQuickSearch();
      setIsSearchInputActive(!!value.length);
      setCurrentSearchPhrase(value);
      if (!!value.length) {
        setQuery(value);
        clearTimeout(typeTimer);
        typeTimer = setTimeout(() => {
          setCurrentSearchPhrase(value);
          QuickSearch(value, requestContext)
            .then((res) => {
              onSearchResultChange(res, value);
            })
            .catch(() => {});
        }, 500);
      } else {
        clearTimeout(typeTimer);
        onSearchResultChange(undefined, '');
      }
    },
    [onSearchResultChange]
  );

  const onInputFocus = () => {
    setTimeout(() => {
      if (!isOpen && !!currentSearchPhrase.length) {
        EventDispatcher.dispatch(ON_SEARCH_MODAL_OPEN_CHANGE, true);
        openQuickSearch();
        setIsSearchInputActive(true);
      }
    }, 100);
  };

  return (
    <Container isActive={isSearchInputActive}>
      <InputField
        onFocus={onInputFocus}
        ref={inputRef}
        onChange={(e) => onInputChange(e.currentTarget.value)}
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
          e.key === 'Enter' && doSearch()
        }
        value={currentSearchPhrase}
        type="text"
        placeholder={placeholderText}
      />
      <SearchButton onClick={doSearch}>
        <StyledSearchIcon size="l" />
      </SearchButton>
      {isOpen && !!currentSearchPhrase.length && (
        <CloseButton onClick={() => onCrossClick()}>
          <CloseIcon size="xs" />
        </CloseButton>
      )}
      {isOpen && (
        <QuickSearchModal
          searchResult={searchResult}
          isOpen={true}
          currentSearchPhrase={currentSearchPhrase}
          setInputActive={setIsSearchInputActive}
          onSearchResultChange={onSearchResultChange}
          closeSearchModal={closeSearchModal}
        />
      )}
    </Container>
  );
}

const Container = styled('div', {
  backgroundColor: '$white',
  h: '50px',
  maxW: 107.5,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  w: '100%',
  mr: 'auto',
  border: '1px solid $searchInputBorderPrimary',
  borderRadius: '73px',
  pl: 10,
  variants: {
    isActive: {
      true: {
        boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.16)',
      },
    },
  },
});

const CloseButton = styled('button', {
  position: 'absolute',
  r: 15,
});

const StyledSearchIcon = styled(SearchIcon, {
  wh: '50px',
  ml: 2,
  l: 0,
});

const InputField = styled('input', {
  backgroundColor: 'transparent',
  color: '$searchInputBlurPrimary',
  flexGrow: 1,
  flexShrink: 1,
  fs: 7,
  mt: 0.25,
  letterSpacing: '1px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:focus': {
    color: '$searchInputTextPrimary',
    outline: 'none',
  },
  '&::placeholder': {
    color: '$grey700',
    fs: 5.5,
  },
});

const SearchButton = styled('button', {
  display: 'flex',
  backgroundColor: '$grey100',
  w: '50px',
  h: '48px',
  border: 'none',
  borderLeft: '1px solid $searchInputBorderPrimary',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0 73px 73px 0',
  '&:focus': {
    outline: 'none',
  },
});

export default React.memo(DesktopQuickSearch);

type UserMenuStateType = {
  searchModalIsOpen: boolean;
  mountSearchModal: boolean;
};

type UserMenuActionType =
  | 'mountAndOpen'
  | 'closeSearchModal'
  | 'unMountSearchModal';

const quickSearchReducer = (
  state: UserMenuStateType,
  action: UserMenuActionType
) => {
  switch (action) {
    case 'mountAndOpen': {
      return {
        ...state,
        searchModalIsOpen: true,
        mountSearchModal: true,
      };
    }
    case 'closeSearchModal': {
      return {
        ...state,
        searchModalIsOpen: false,
      };
    }
    case 'unMountSearchModal': {
      return {
        ...state,
        mountSearchModal: false,
        searchModalIsOpen: false,
      };
    }
  }
};

export default quickSearchReducer;

type UserMenuStateType = {
  userMenuIsOpen: boolean;
  mountUserMenu: boolean;
};

type UserMenuActionType = 'mountAndOpen' | 'closeUserMenu' | 'unMountUserMenu';

const userMenuReducer = (
  state: UserMenuStateType,
  action: UserMenuActionType
) => {
  switch (action) {
    case 'mountAndOpen': {
      return {
        ...state,
        userMenuIsOpen: true,
        mountUserMenu: true,
      };
    }
    case 'closeUserMenu': {
      return {
        ...state,
        userMenuIsOpen: false,
      };
    }
    case 'unMountUserMenu': {
      return {
        ...state,
        mountUserMenu: false,
        userMenuIsOpen: false,
      };
    }
  }
};

export default userMenuReducer;

import { useRef, useReducer, useState, useEffect } from 'react';
import HeaderMain from '../HeaderMain';
import loadable, { LoadableComponent } from '@loadable/component';
import miniCartReducer from '../Reducers/miniCartReducer';
import userMenuReducer from '../Reducers/userMenuReducer';
import menuReducer from '../Reducers/menuReducer';
import { PropType as MiniCartPropType } from '../../MiniCart/MiniCart';
import { PropType as UserMenuPropType } from '../../../Shared/NavigationMenu/UserMenu';
import { styled } from '../../../stitches.config';
import quickSearchReducer from '../Reducers/quickSearchReducer';
import Overlay from '../../../Atoms/Overlay/Overlay';
import { useHeaderData } from '../Header';

const Menu = loadable(() => import('../../NavigationMenu/NavigationMenu'));

const UserMenu: LoadableComponent<UserMenuPropType> = loadable(
  () => import('../../../Shared/NavigationMenu/UserMenu')
);

const MiniCart: LoadableComponent<MiniCartPropType> = loadable(
  () => import('../../MiniCart/MiniCart')
);

function DesktopHeader() {
  const {
    topLinks,
    languagePicker,
    logo,
    topLinkPrivate,
    topLinkCompany,
    topText,
    topResellerLink,
  } = useHeaderData();
  const hasBottomSection = topLinks && !!topLinks.length;

  const [placeholderPadding, setPlaceholderPadding] = useState<number>(0);

  useEffect(() => {
    let headerElm = document.getElementById('header');
    headerElm && setPlaceholderPadding(headerElm.offsetHeight);
  });

  const [{ menuIsOpen, mountMenu }, menuDispatch] = useReducer(menuReducer, {
    menuIsOpen: false,
    mountMenu: true,
  });
  const [{ miniCartIsOpen, mountMiniCart }, miniCartDispatch] = useReducer(
    miniCartReducer,
    {
      miniCartIsOpen: false,
      mountMiniCart: true,
    }
  );

  const [{ userMenuIsOpen, mountUserMenu }, userMenuDispatch] = useReducer(
    userMenuReducer,
    {
      userMenuIsOpen: false,
      mountUserMenu: true,
    }
  );

  const [{ searchModalIsOpen, mountSearchModal }, quickSearchDispatch] =
    useReducer(quickSearchReducer, {
      searchModalIsOpen: false,
      mountSearchModal: true,
    });

  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const miniCartBtnRef = useRef<HTMLButtonElement>(null);
  const userMenuBtnRef = useRef<HTMLButtonElement>(null);

  const placeholderStyle = {
    paddingTop: placeholderPadding,
  };

  return (
    <>
      <Placeholder css={placeholderStyle} />
      <HeaderTag id="header">
        <HeaderMain
          toggleMenu={() =>
            menuDispatch(menuIsOpen ? 'closeMenu' : 'mountAndOpen')
          }
          openMiniCart={() =>
            miniCartDispatch(miniCartIsOpen ? 'closeMiniCart' : 'mountAndOpen')
          }
          openUserMenu={() =>
            userMenuDispatch(userMenuIsOpen ? 'closeUserMenu' : 'mountAndOpen')
          }
          openQuickSearch={() =>
            quickSearchDispatch(
              searchModalIsOpen ? 'closeSearchModal' : 'mountAndOpen'
            )
          }
          closeSearchModal={() => quickSearchDispatch('closeSearchModal')}
          menuIsOpen={menuIsOpen}
          quickSearchIsOpen={searchModalIsOpen}
          ref={menuBtnRef}
          miniCartBtnRef={miniCartBtnRef}
          userMenuBtnRef={userMenuBtnRef}
          topLinks={topLinks}
          topLinkPrivate={topLinkPrivate}
          topLinkCompany={topLinkCompany}
          topText={topText}
          topResellerLink={topResellerLink}
          logo={logo}
          languagePicker={languagePicker}
        />
        <MenuContainer>
          {mountMenu && (
            <Menu
              isOpen={menuIsOpen}
              onMenuClose={() => menuDispatch('closeMenu')}
            />
          )}
          {mountUserMenu && (
            <UserMenu
              isOpen={userMenuIsOpen}
              onMenuClose={() => userMenuDispatch('closeUserMenu')}
            />
          )}
        </MenuContainer>
        {mountMiniCart && (
          <MiniCart
            isOpen={miniCartIsOpen}
            openMiniCart={() => miniCartDispatch('mountAndOpen')}
            closeMiniCart={() => miniCartDispatch('closeMiniCart')}
          />
        )}
        {searchModalIsOpen && <Overlay />}
      </HeaderTag>
    </>
  );
}

const HeaderTag = styled('header', {
  zIndex: '$Header',
  backgroundColor: '$backgroundPrimary',
  position: 'fixed',
  t: 0,
  w: '100%',
});

const Placeholder = styled('div', {
  w: '100%',
});

const MenuContainer = styled('div', {
  maxWidth: '$screenMaxWidth',
  position: 'fixed',
  px: 4,
  py: 0,
  mx: 'auto',
  my: 0,
});

export default DesktopHeader;

import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import IKexCart from '../../Cart/Models/KexCart.interface';
import Fetcher from '../../Shared/Common/Fetcher';
import { IS_PRODUCTION_ENV } from '../../Shared/Configs/EnvConfig';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import {
  EventDispatcher,
  NOTIFY_ACTION,
} from '../../Shared/Common/EventDispatcher';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';

let abortController: AbortController = new AbortController();
let hasMounted = false;
let cartUrl = '';

type CartReturnType = {
  cart: IKexCart;
  isLoading: boolean;
};

export function GetCart(): CartReturnType {
  const tempCartObject: IKexCart = {} as IKexCart;
  const [cart, setCart] = useState<IKexCart>(tempCartObject);
  const { pageCacheTime } = useAppSettingsData();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { languageRoute } = useAppSettingsData();
  const { pageId, channelId } = useCurrentPage();

  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    channelSystemId: channelId,
  });

  cartUrl = `/api/${languageRoute || 'en'}/Cart/GetCart`;

  const { data: fetchedCart } = useSWR<IKexCart>(
    cartUrl,
    () => FetchCart(cartUrl, litiumContext),
    {
      initialData: undefined,
      revalidateOnFocus: IS_PRODUCTION_ENV,
      dedupingInterval: pageCacheTime,
    }
  );

  useEffect(() => {
    if (!hasMounted) {
      hasMounted = true;
    } else {
      if (fetchedCart) {
        setCart(fetchedCart);
        setIsLoading(false);
      }
    }
  }, [fetchedCart]);

  return { cart, isLoading };
}

export async function RemoveFromCart(
  productCode: string,
  languageRoute: string,
  litiumContext: string
) {
  cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
  const res = await fetch(`${cartUrl}RemoveItem?code=${productCode}`, {
    method: 'POST',
    headers: {
      'litium-request-context': litiumContext,
    },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
  }
}

export async function UpdateCart(
  productCode: string,
  quantity: number,
  languageRoute: string,
  litiumContext: string,
  setIsLoading?: (value: boolean) => void
) {
  setIsLoading && setIsLoading(true);
  cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
  const updateQuantity = quantity;
  const res = await fetch(
    `${cartUrl}Update?code=${productCode}&quantity=${updateQuantity}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': litiumContext,
      },
    }
  );
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
    setIsLoading && setIsLoading(false);
    return true;
  }
  setIsLoading && setIsLoading(false);
  return false;
}

export async function ClearCart(
  languageRoute: string,
  litiumContext: string,
  setIsLoading?: (value: boolean) => void,
  openMiniCart?: () => void
) {
  setIsLoading && setIsLoading(true);
  cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
  const res = await fetch(`${cartUrl}ClearCart`, {
    method: 'POST',
    headers: {
      'litium-request-context': litiumContext,
    },
  });
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    openMiniCart && openMiniCart();
    mutate(`${cartUrl}GetCart`, cart, false);
    setIsLoading && setIsLoading(false);
    return true;
  }
  setIsLoading && setIsLoading(false);
  return false;
}

export async function SetQuantity(
  productCode: string,
  quantity: number,
  languageRoute: string,
  litiumContext: string
) {
  const updateQuantity = quantity;
  cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
  const res = await fetch(
    `${cartUrl}SetQuantity?code=${productCode}&quantity=${updateQuantity}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': litiumContext,
      },
    }
  );
  if (res.ok) {
    const { cart, notification } = await res.json();
    EventDispatcher.dispatch(NOTIFY_ACTION, notification);
    mutate(`${cartUrl}GetCart`, cart, false);
  }
}

export function EmptyCart() {
  mutate(`${cartUrl}GetCart`, { numberOfItems: 0 } as IKexCart, false);
}

function FetchCart(url: string, litiumContext: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  return Fetcher<IKexCart, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data.cart);
    },
    litiumContext
  );
}

import { styled } from '../../stitches.config';
import useLockBodyScroll from '../../Shared/Hooks/useLockBodyScroll';
import { timings } from '../../Theme/Settings/animation';
import { overlayFadeIn } from '../../Theme/Settings/keyframes';

type PropTypes = {
  clickEvnt?: () => void;
};

function Overlay({ clickEvnt }: PropTypes) {
  useLockBodyScroll();

  return <OverlayDiv onClick={clickEvnt} />;
}

export default Overlay;

const OverlayDiv = styled('div', {
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '$black',
  opacity: 0.5,
  zIndex: '$Overlay',
  transitionProperty: 'opacity',
  transitionDuration: '0.3s',
  transitionTimingFunction: 'ease-in',
  animation: `${overlayFadeIn} ${timings.oneHalf}`,
});

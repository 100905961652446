import Fetcher from '../../Shared/Common/Fetcher';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';
import { canUseDOM } from '../../Shared/Common/Helpers';
import {
  EventDispatcher,
  ON_SEARCH,
} from '../../Shared/Common/EventDispatcher';

let abortController: AbortController = new AbortController();

export enum SearchTypes {
  Products = 'getProduct',
  Content = 'getContent',
  Categories = 'getCategory',
  Sector = 'getSector',
  Documents = 'getDocument',
  Empty = '',
}

export async function InitSearchResult(
  take: number,
  query: string,
  requestContext: string,
  languageRoute?: string
) {
  const url = canUseDOM()
    ? `/api/${
        languageRoute || 'en'
      }/search/GetSearchResult?query=${query}&take=${take}&initialSearch=true&getProduct=true&getContent=true&sortBy=name`
    : '';

  return await FetchSearch(url, requestContext);
}

export async function GetSearchResult(
  query: string,
  requestContext: string,
  languageRoute: string,
  take: number,
  type: SearchTypes,
  currentData?: FullSearchResult,
  skip?: number,
  sortBy?: string,
  sortDirection?: number,
  minPrice?: number,
  maxPrice?: number
) {
  const url = canUseDOM()
    ? `/api/${languageRoute || 'en'}/search/GetSearchResult?query=${query}${
        take ? `&take=${take}` : ''
      }&skip=${skip}${sortBy ? `&sortBy=${sortBy}` : '&sortBy=name'}${
        sortDirection ? `&sortDirection=${sortDirection}` : ''
      }${`&priceFrom=${minPrice ? minPrice : 0}`}${
        maxPrice ? `&priceTo=${maxPrice}` : ''
      }`
    : '';

  const res = await FetchSearch(url, requestContext);
  if (res) {
    let newRes;
    switch (type) {
      case SearchTypes.Products:
        if (currentData) {
          newRes = {
            ...currentData,
            productSearchResult: {
              ...res.productSearchResult,
              items: [
                ...currentData.productSearchResult.items,
                ...res.productSearchResult.items,
              ],
            },
          };

          return newRes;
        }

        return res;
      case SearchTypes.Content:
        if (currentData) {
          newRes = {
            ...currentData,
            contentSearchResult: {
              ...res.contentSearchResult,
              items: [
                ...currentData.contentSearchResult.items,
                ...res.contentSearchResult.items,
              ],
            },
          };

          break;
        }

        return res;
      default:
        newRes = res;
    }
    return newRes;
  }
}

export async function InitCategoryItems(
  pageId: string,
  take: number,
  litiumContext: string,
  languageRoute: string,
  queryString?: string
) {
  const url = canUseDOM()
    ? `/api/${
        languageRoute || 'en'
      }/category/GetCategoryItems?pageId=${pageId}&sortBy=name&sortDirection=ascending&initialSearch=true${
        take ? `&take=${take}` : ''
      }${queryString ? `&${queryString}` : ''}`
    : '';

  return await FetchSearch(url, litiumContext);
}

export async function GetCategoryItems(
  pageId: string,
  take: number,
  litiumContext: string,
  languageRoute: string,
  currentData?: FullSearchResult,
  skip?: number,
  sortBy?: string,
  sortDirection?: number,
  queryString?: string
) {
  const url = canUseDOM()
    ? `/api/${
        languageRoute || 'en'
      }/category/GetCategoryItems?pageId=${pageId}&${
        take ? `take=${take}` : ''
      }${sortBy ? `&sortBy=${sortBy}` : '&sortBy=name'}${
        sortDirection ? `&sortDirection=${sortDirection}` : ''
      }${skip ? `&skip=${skip}` : ''}${queryString ? `&${queryString}` : ''}`
    : '';

  const res = await FetchSearch(url, litiumContext);

  if (res && currentData) {
    let newRes;
    newRes = {
      ...res,
      productSearchResult: {
        ...res.productSearchResult,
        items: [
          ...currentData.productSearchResult.items,
          ...res.productSearchResult.items,
        ],
      },
    };

    return newRes;
  }
  return res;
}

export async function QuickSearch(url: string, litiumContext: string) {
  const urll = canUseDOM()
    ? `/api/en/search/QuickSearch?query=${url}&sortBy=name`
    : '';

  const res = await FetchSearch(urll, litiumContext);

  return res;
}

function FetchSearch(url: string, litiumContext: string) {
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  EventDispatcher.dispatch(ON_SEARCH, true);

  return Fetcher<FullSearchResult, FullSearchResult>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      EventDispatcher.dispatch(ON_SEARCH, false);
    },
    litiumContext
  );
}

import { styled } from '../../stitches.config';

type PropTypes = {
  type?: 'max' | 'vertical' | 'header' | 'headerLogo';
  marginTop?: boolean;
  noMargin?: boolean;
  css?: any;
};

export const Divider = ({
  type,
  marginTop,
  noMargin,
  css,
  ...rest
}: PropTypes) => {
  return (
    <BaseDivider
      css={css}
      type={type}
      {...(marginTop && { marginTop })}
      {...(noMargin && { noMargin })}
      {...rest}
    />
  );
};

const BaseDivider = styled('div', {
  h: 0.25,
  w: 16,
  mt: 0,
  mb: 6,
  backgroundColor: '$grey100',
  '@mediaMaxSmall': {
    mt: 6,
    mb: 3,
  },
  variants: {
    type: {
      max: {
        w: '100%',
        mx: 4,
      },
      vertical: {
        w: 0.25,
        h: '100%',
        my: 0,
        mx: 6,
      },
      header: {
        opacity: 0.5,
        h: 0,
        w: 0,
        my: 0,
        mx: 3,
      },
      headerLogo: {
        opacity: 0.5,
        h: 0,
        w: 0,
        my: 0,
        mx: 6,
      },
    },
    marginTop: {
      true: {
        mt: 6,
      },
    },
    noMargin: {
      true: {
        m: 0,
      },
    },
  },
});

export default Divider;

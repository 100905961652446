type MiniCartStateType = {
  miniCartIsOpen: boolean;
  mountMiniCart: boolean;
};

type MiniCartActionType = 'mountAndOpen' | 'closeMiniCart' | 'unMountMiniCart';

const miniCartReducer = (
  state: MiniCartStateType,
  action: MiniCartActionType
) => {
  switch (action) {
    case 'mountAndOpen': {
      return {
        ...state,
        miniCartIsOpen: true,
        mountMiniCart: true,
      };
    }
    case 'closeMiniCart': {
      return {
        ...state,
        miniCartIsOpen: false,
      };
    }
    case 'unMountMiniCart': {
      return {
        ...state,
        mountMiniCart: false,
        miniCartIsOpen: false,
      };
    }
  }
};

export default miniCartReducer;

import { useRef, useState } from 'react';
import { ChevronIcon } from '../../Atoms/Icons';
import Image from '../../Atoms/Image/Image';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import useOutsideClick from '../../Shared/Hooks/useOutsideClick';
import Language from '../../Shared/Models/LanguagePicker/Language.interface';
import LanguagePickerModel from '../../Shared/Models/LanguagePickerModel.interface';
import { styled } from '../../stitches.config';

type PropTypes = {
  languagePicker: LanguagePickerModel;
};

function LanguagePicker({ languagePicker }: PropTypes) {
  const { languageRoutes } = useCurrentPage();
  const [showDropdown, setShowDropdown] = useState(false);
  const languagePickerRef = useRef(null);
  const selectedLanguage = languagePicker.selectedLanguage;

  const onLanguageClick = (language: Language, e: any) => {
    e.preventDefault();
    if (language.countryCode !== undefined) {
    }

    if (languageRoutes[language.countryCode] !== undefined) {
      window.location.href = languageRoutes[language.countryCode];
    } else {
      window.location.href = language.url;
    }
  };

  useOutsideClick(languagePickerRef, () => {
    showDropdown && setShowDropdown(false);
  });

  return (
    <Root>
      <LanguagePickerWrapper
        onClick={() => setShowDropdown(!showDropdown)}
        ref={languagePickerRef}
      >
        <SelectedLanguage>
          <Flex disableDropdown={languagePicker.disableDropdown}>
            {selectedLanguage?.flagImage?.src && (
              <FlagImageWrapper>
                <FlagImage
                  src={selectedLanguage?.flagImage?.src}
                  alt={selectedLanguage?.flagImage?.alt}
                />
              </FlagImageWrapper>
            )}
            <SelectedSpan>
              {selectedLanguage.name} / {selectedLanguage.currency}
            </SelectedSpan>
            {!languagePicker.disableDropdown && (
              <ChevronIcon
                size={'xs'}
                rotateDown
                rotateUp={showDropdown}
                transition
              />
            )}
          </Flex>
          {!languagePicker.disableDropdown && showDropdown && (
            <Dropdown>
              {languagePicker.languages.map(
                (language: Language) =>
                  language.countryCode !==
                    languagePicker.selectedLanguage.countryCode && (
                    <LinkWrapper>
                      <StyledLink
                        href={
                          languageRoutes[language.channelSystemId] !== undefined
                            ? languageRoutes[language.channelSystemId].url
                            : language.url
                        }
                        onClick={() => onLanguageClick(language, null)}
                      >
                        {language?.flagImage?.src && (
                          <FlagImageWrapper>
                            <FlagImage
                              src={language?.flagImage?.src}
                              alt={language?.flagImage?.alt}
                            />
                          </FlagImageWrapper>
                        )}
                        {language.name} / {language.currency}
                      </StyledLink>
                    </LinkWrapper>
                  )
              )}
            </Dropdown>
          )}
        </SelectedLanguage>
      </LanguagePickerWrapper>
    </Root>
  );
}

export default LanguagePicker;

const Root = styled('div', { ml: 'auto', py: 1 });

const LanguagePickerWrapper = styled('div', {});

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  px: 1,
  variants: { disableDropdown: { false: { cursor: 'pointer' } } },
});

const StyledLink = styled('a', {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  w: '100%',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const FlagImageWrapper = styled('div', {
  alignItems: 'center',
  display: 'flex',
  h: '24px',
  w: '24px',
  mr: '10px',
});

const FlagImage = styled(Image, {});

const SelectedLanguage = styled('div', {
  fontWeight: '$fw700',
  position: 'relative',
});

const LinkWrapper = styled('div', {
  display: 'flex',
  py: 1,
});

const Dropdown = styled('div', {
  position: 'absolute',
  background: '$rootBackgroundColor',
  zIndex: 1,
  r: -4,
  l: -4,
  pb: 1,
  pt: 3,
  px: 5,
});

const SelectedSpan = styled('span', {
  mr: '8px',
  textDecoration: 'none',
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '17px',
  letterSpacing: '1px',
});

import { useRef } from 'react';
import useOnScreen from '../../Shared/Hooks/useOnScreen';
import { styled } from '../../stitches.config';

type PropType = {
  src: string;
  alt: string;
  css?: any;
  imgFit?: boolean;
  onLoad?: () => void;
  usePlaceHolder?: boolean;
  title?: string;
  lazy?: boolean;
};

const Image = ({
  src,
  alt = '',
  css,
  onLoad,
  usePlaceHolder,
  title = '',
  lazy = true,
  ...rest
}: PropType) => {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen({ ref });

  return onScreen || !lazy ? (
    <StyledImage
      css={css}
      src={src}
      alt={alt}
      title={title}
      aria-label={alt}
      onLoad={onLoad}
      {...rest}
    />
  ) : usePlaceHolder ? (
    <div ref={ref} />
  ) : (
    <span ref={ref} />
  );
};

const StyledImage = styled('img', {
  w: '100%',
});

export default Image;
